import React, { useEffect, useState } from "react";
import logo from './logo.png';
import pin from './pin.png';
import service1 from './service-1.png';
import service2 from './service-2.png';
import service3 from './service-3.png';
import service4 from './service-4.png';
import bgheader from './bg-header.png';
import aboutus from './aboutus.png';
import Modal from './Modal';
import Header from './Header.js';
import Stickyheader from './Stickyheader.js';
import "./App.css";
import Content from './Popup.js';
import { Helmet } from "react-helmet";


const Home = () => {
  const [logo, setLogo] = useState('');

  useEffect(() => {
    loadLogo();
 }, []);


 const loadLogo=()=>{
  fetch("https://apimenu3.haikutheasiankitchen.com/index.php/Api/logo", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        //console.log(result);
        if (result.data != "") {
          const data = result.data;
          setLogo(data);
        }
      })
    .catch((err) => {
      console.log(err.message);
    });
}

  return (
    <>

  <Header />
  <Stickyheader />
  <Content />
  <div className="container-fluid">
    <section className="top-banneer">
      <div className="container">
        <img src={bgheader} alt="bg-header" />
      </div>
    </section>
    <section className="aboutus" id="Aboutus">
      <div className="container">
        <div className="d-flex">
          <div className="left-cont">
            <img src={aboutus} />
          </div>
          <div className="right-cont">
            <span className="subtitle">Few words about us</span>
            <h2>About Us</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi
              vel, reiciendis dolorem fugiat beatae voluptate unde distinctio
              nemo assumenda error eaque voluptas cupiditate minima omnis vitae.
              Similique suscipit ab sint.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi
              vel, reiciendis dolorem fugiat beatae voluptate unde distinctio
              nemo assumenda error eaque voluptas cupiditate minima omnis vitae.
              Similique suscipit ab sint.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi
              vel, reiciendis dolorem fugiat beatae voluptate unde distinctio
              nemo assumenda error eaque voluptas cupiditate minima omnis vitae.
              Similique suscipit ab sint.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="why-choose-we">
      <div className="top-heading">
        <h2>Why choose we</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi vel,
          reiciendis dolorem fugiat beatae voluptate unde distinctio nemo
          assumenda error eaque voluptas cupiditate minima omnis vitae.
          Similique suscipit ab sint.
        </p>
      </div>
      <div className="container">
        <div className="choose-cont">
          <div className="card">
            <figure>
              <div className="img-wrap">
                <img src={service1} />
              </div>
              <figcaption>
                <h3>Experienced chefs</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              </figcaption>
            </figure>
          </div>
          <div className="card">
            <figure>
              <div className="img-wrap">
                <img src={service2} />
              </div>
              <figcaption>
                <h3>Original recipes</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              </figcaption>
            </figure>
          </div>
          <div className="card">
            <figure>
              <div className="img-wrap">
                <img src={service3} />
              </div>
              <figcaption>
                <h3>Fresh ingredients</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              </figcaption>
            </figure>
          </div>
          <div className="card">
            <figure>
              <div className="img-wrap">
                <img src={service4} />
              </div>
              <figcaption>
                <h3>Convenient location</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </section>
    <section className="ourmenu" id="Ourmenu">
      <div className="top-heading">
        <h2>Our Menu</h2>
        <ul className="main-menu ourmain-menu">
          <li>
            <a href="#">Pasta</a>
          </li>
          <li>
            <a href="#">Salad</a>
          </li>
          <li>
            <a href="#">Soups</a>
          </li>
          <li>
            <a href="#">Croissants</a>
          </li>
          <li>
            <a href="#">Doner</a>
          </li>
        </ul>
      </div>
    </section>
    <section className="contact" id="Contact">
      <div className="container">
        <div className="d-flex">
          <div className="equal-cont">
            <span className="subtitle">REGISTER</span>
            <h2>Fill Form</h2>
            <form>
              <input type="text" name="name" placeholder="Name" />
              <input
                type="number"
                maxLength={10}
                name="phone"
                placeholder="Phone"
              />
              <input type="email" name="email" placeholder="Email" />
              <input type="text" name="password" placeholder="Password" />
              <button type="submit" name="submit">
                Submit
              </button>
            </form>
          </div>
          <div className="equal-cont">
            <span className="subtitle">ADDRESS</span>
            <h2>About Us</h2>
            <div className="single-add">
              <h3>Address</h3>
              <p>2900 Lapeer Rd, Port Hurons, MI 49070</p>
            </div>
            <div className="single-add">
              <h3>Phone</h3>
              <p>8005550855</p>
              <p>8005550855</p>
            </div>
            <div className="single-add">
              <h3>Email</h3>
              <p>info@companyname.com</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</>
  )
}

export default Home