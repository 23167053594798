import React, { useState, useEffect } from 'react';
import "rc-pagination/assets/index.css";
import '../../../DataTable.css';

function FeedbackTable() {
  const itemsPerPage = 10;
  const [data, setAllData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total number of pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Calculate start and end index of current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get current page data
  const currentData = data.slice(startIndex, endIndex);

  useEffect(() => {
    loadcategory();
  }, []);

  const loadcategory = () => {
    fetch("https://apimenu3.haikutheasiankitchen.com/index.php/Api/feedback", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.data !== "") {
          const data = result.data;
          setAllData(data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pages = [];
    let startPage, endPage;
    if (totalPages <= 7) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 4) {
        startPage = 1;
        endPage = 7;
      } else if (currentPage + 3 >= totalPages) {
        startPage = totalPages - 6;
        endPage = totalPages;
      } else {
        startPage = currentPage - 3;
        endPage = currentPage + 3;
      }
    }
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          className={`paginationCategory ${currentPage === i ? 'active' : ''}`}
          key={i}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }
    return pages;
  };

  return (
    <>
      <style>{`
        @media (max-width: 768px) {
          .table-responsive1 {
            display: flex;
            width: 100%;
            -webkit-overflow-scrolling: touch;
            overflow-x: auto;
            flex-wrap: wrap;
          }
        }
        .paginationCategory {
          margin: 0 5px;
        }
        .paginationCategory.active {
          font-weight: bold;
        }
      `}</style>
      <div className="col-12">
        <div className="ms-panel">
          <div className="ms-panel-header">
            <div className="container">
              <div className="row">
                <div className="col-md-10">
                  <h6>Feedback Report</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="ms-panel-body">
            <div className="table-responsive1">
              <table className="table table-hover thead-primary">
                <thead>
                  <tr>
                    <td>Branch</td>
                    <td>User Name</td>
                    <td>Email</td>
                    <td>Phone Number</td>
                    <td>When Did You Visit Us?</td>
                    <td>How Did You Discover Us?</td>
                    <td>Rate Your Overall Experience!</td>
                    <td>Food Quality:</td>
                    <td>Service Quality:</td>
                    <td>Atmosphere:</td>
                    <td>What Was Your Favorite Dish?</td>
                    <td>What Can We Improve?</td>
                    <td>Would You Recommend Us to Friends?</td>
                    <td>Any Fun Stories or Additional Comments?</td>

                  </tr>
                </thead>
                <tbody>
                  {currentData.map((data, i) => (
                    <tr key={i}>
                      <td>{data.branch}</td>
                      <td>{data.name}</td>
                      <td>{data.email}</td>
                      <td>{data.phone}</td>
                      <td>{data.qns1}</td>
                      <td>{data.qns2}</td>
                      <td>{data.qns3}</td>
                      <td>{data.qns4}</td>
                      <td>{data.qns5}</td>
                      <td>{data.qns6}</td>
                      <td>{data.qns7}</td>
                      <td>{data.qns8}</td>
                      <td>{data.qns9}</td>
                      <td>{data.qns10}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="pagination">
                <button className='addcategory'
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {renderPageNumbers()}
                <button className='addcategory'
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FeedbackTable;
