// SortableContainer.js
import React from 'react';
import Category from './Category';

const SortableContainer = ({ categories, moveCategory, moveSubCategory, saveAllOrder }) => {
  return (
    <div >
      {categories.map((category, index) => (
        <Category
          key={category.category.id}
          category={category}
          index={index}
          moveCategory={moveCategory}
          moveSubCategory={moveSubCategory}
        />
      ))}
      <div className="ms-panel-header new">
        <button className="btn btn-primary d-block" type="button" onClick={saveAllOrder}>
          Save
        </button>
      </div>
      
    </div>
  );
};

export default SortableContainer;
