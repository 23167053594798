import React, { useEffect, useState } from "react";
import Header from './Header.js';
import "./App.css";




const MenuSelection = () => {
  const [data, setData] = useState([]);
  const [productData, setproductData] = useState([]);

  useEffect(() => {
    loadData();
    productsData();
    
 }, []);

 const productsData=()=>{
  fetch("https://apimenu3.haikutheasiankitchen.com/index.php/Api/allProductsdata", {
    method: "POST",
    body: JSON.stringify({
      main_category_id: 1,
  }),
    headers: {
      Accept: "application/json",
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((res) => res.json())
    .then((result) => {
      //console.log(result);
      if (result.data != "") {
        setproductData(result.data);
      }
    })
  .catch((err) => {
    console.log(err.message);
  });
}

  const loadData=()=>{
    fetch("https://apimenu3.haikutheasiankitchen.com/index.php/Api/maincategories", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          //console.log(result);
          if (result.data !== "") {
            const data = result.data;
            setData(data);

          }
        })
      .catch((err) => {
        console.log(err.message);
      });
  }
  return (
    <>
    
  <Header />
<div className="container-fluid">
	<div className="menu-cont-page">
	  <div className="container">
		<div className="title-cont">
		  <h1>Dine-In Menu</h1>
		  <p className="sub-title">Select your category below.</p>
		</div>
		<div className="click-menu">
    {data.map((item, i) => ( 
		  <div className="bg-menu" style={{backgroundImage: `url(${item.image})`}}>
			<a href={"/menu/"+item.id} title>
			  <h3 className="item">{item.category_name}</h3>
			</a>
		  </div>
    ))}
		
		</div>
	  </div>
	</div>
  </div>
  <div className="sticky-menu">
        <ul>
        {productData.map((item, i) => (
          <li className="footer-listitem"   key={i}> <a href={"/menu#section-"+i} >{item.category}</a></li>
        ))}
        </ul>
      </div>

</>
  )
}

export default MenuSelection