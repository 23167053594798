import React, { Component } from 'react';
import Sidenavigation from '../layouts/Sidenavigation';
import Topnavigation from '../layouts/Topnavigation';
import Breadcrumb from '../sections/Orders/Breadcrumb';
import Favorder from '../sections/Orders/Favorder';
import CategoryTable from '../sections/Orders/CategoryTable';
import Quickbar from '../layouts/Quickbar';

class Category extends Component {
    render() {
        return (
            <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
                <Sidenavigation />
                <main className="body-content">
                    <Topnavigation />
                    <div className="ms-content-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb pl-0">
                                        <li className="breadcrumb-item"><a href="/"><i className="material-icons">home</i> Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Category</li>
                                    </ol>
                                </nav>
                                <CategoryTable/>
                            </div>
                        </div>
                    </div>
                </main>
                <Quickbar />
            </div>
        );
    }
}

export default Category;