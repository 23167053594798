// import React, { useState } from 'react';
// import axios from 'axios';

// const Testing = () => {
//   const [file, setFile] = useState(null);
//   const [formData, setFormData] = useState({
//     productname: 'venky',
//     category: '',
//     type: '',
//     price: '',
//     status:'',
//     description:'',
//     image:'',
//     addon:[]

//     // Add more fields as needed
//   });

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//   };

//   const handleFileUpload = async () => {
//     try {
//       const formDataObj = new FormData();
//       formDataObj.append('file', file);
//       formDataObj.append('productname', formData.productname);

//       const response = await axios.post('https://apimenu3.haikutheasiankitchen.com/index.php/Api/image', formDataObj, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });

//       console.log('File upload response:', response.data);
//     } catch (error) {
//       console.error('Error uploading file:', error);
//     }
//   };

//   return (
//     <div>

//       <input type="file" onChange={handleFileChange} />
//       <button onClick={handleFileUpload}>Upload File</button>
//     </div>
//   );
// };

// export default Testing;



// import React, {useState, useEffect} from 'react';
// import axios from 'axios';

// function Testing() {
//     const [post, setPost] = useState({});
//     const id = 1;

//     const handleChange = ({target}) => {
//         const {name, value} = target;
//         setPost({...post, [name]: value});
//         console.log(post);
//     };

//     const loadData=()=>{
//       fetch("https://apimenu3.haikutheasiankitchen.com/index.php/Api/Productdata", {
//           method: "POST",
//           body: JSON.stringify({
//               pid: 35,
//           }),
//           headers: {
//           Accept: "application/json",
//           "Content-type": "application/json; charset=UTF-8",
//           },
//       })
//           .then((res) => res.json())
//           .then((result) => {
//           //console.log(result);

//               if (result.data!=="") {
//                   //setProdName(result.data.prodInfo.name)
//                   setPost(result.data.prodInfo);
                 
//               }
//           })
//       .catch((err) => {
//           //this.setState({ error: 'Invalid username or password' });
//           console.log(err.message);

//       });
  
// }

// useEffect(() => {

//   loadData();

  
// }, []);
//     const handleSubmit = async e => {
//         e.preventDefault();

//         const editDataById = async () => {
//             try {
//                 const response = await axios.put(`https://jsonplaceholder.typicode.com/posts/${id}`, {
//                     method: 'PUT',
//                     body: JSON.stringify({
//                         id: id,
//                         title: post.name,
//                         body: post.description,
//                         userId: 1
//                     }),
//                     headers: {
//                         "Content-type": "application/json; charset=UTF-8"
//                     }
//                 })
//                     .then(response => response.json())
//                     .then(json => console.log(json));
//                 console.warn(response.data);
//             } catch (error) {
//                 console.warn(error);
//             }
//         };
//         editDataById();
//     };
//     return (
//         <div className='container'>
//             <div className='row'>
//                 <div className='col-4'>
//                     <form onSubmit={handleSubmit}>
//                         <div className="form-group">
//                             <label htmlFor="name">Title</label>
//                             <input type="text" name='name' value={post.name} onChange={handleChange}
//                                    className="form-control" id="name"/>
//                         </div>
//                         <div className="form-group">
//                             <label htmlFor="position">Body</label>
//                             <input type="text" name='description' value={post.description}
//                                    onChange={handleChange} className="form-control" id="description"/>
//                         </div>
//                         <button type="submit" className="btn btn-primary">Submit</button>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default Testing;


// import React, { useState } from 'react';

// const Testing = () => {
//   const [data, setData] = useState([
//     { id: 1, name: 'Item 1' },
//     { id: 2, name: 'Item 2' },
//     { id: 3, name: 'Item 3' },
//   ]);

//   const removeItem = (idToRemove) => {
//     // Find the index of the item with the matching id
//     const indexToRemove = data.findIndex(item => item.id === idToRemove);

//     if (indexToRemove !== -1) {
//       // Create a new array without the item at the found index
//       const newData = [...data.slice(0, indexToRemove), ...data.slice(indexToRemove + 1)];

//       // Update the state with the new array
//       setData(newData);
//     }
//   };

//   return (
//     <div>
//       <ul>
//         {data.map(item => (
//           <li key={item.id}>
//             {item.name}
//             <button onClick={() => removeItem(item.id)}>Remove</button>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default Testing;


// import React from "react";
// import cloneDeep from "lodash/cloneDeep";
// import throttle from "lodash/throttle";
// import Pagination from "rc-pagination";
// import "rc-pagination/assets/index.css";
// import './DataTable.css';


// const allData = [
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale2",
//     parentId: "123",
//     campaignType: "Inapp",
//     status: "Failed",
//     channel: "iOS",
//     action: ":"
//   },
//   {
//     name: "Sale34",
//     parentId: "45",
//     campaignType: "Email",
//     status: "sent",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   },
//   {
//     name: "Sale",
//     parentId: "12",
//     campaignType: "Push",
//     status: "Failed",
//     channel: "android",
//     action: ":"
//   }
// ];


// const tableHead = {
//   name: "Campaign Name",
//   parentId: "Campaign Id",
//   campaignType: "Type",
//   status: "Status",
//   channel: "Channel",
//   action: "Actions"
// };

// const Testing = () => {
//   const countPerPage = 10;
//   const [value, setValue] = React.useState("");
//   const [currentPage, setCurrentPage] = React.useState(1);
//   const [collection, setCollection] = React.useState(
//     cloneDeep(allData.slice(0, countPerPage))
//   );
//   const searchData = React.useRef(
//     throttle(val => {
//       const query = val.toLowerCase();
//       setCurrentPage(1);
//       const data = cloneDeep(
//         allData
//           .filter(item => item.name.toLowerCase().indexOf(query) > -1)
//           .slice(0, countPerPage)
//       );
//       setCollection(data);
//     }, 400)
//   );

//   React.useEffect(() => {
//     if (!value) {
//       updatePage(1);
//     } else {
//       searchData.current(value);
//     }
//   }, [value]);

//   const updatePage = p => {
//     setCurrentPage(p);
//     const to = countPerPage * p;
//     const from = to - countPerPage;
//     setCollection(cloneDeep(allData.slice(from, to)));
//   };

//   const tableRows = rowData => {
//     const { key, index } = rowData;
//     const tableCell = Object.keys(tableHead);
//     const columnData = tableCell.map((keyD, i) => {
//       return <td key={i}>{key[keyD]}</td>;
//     });

//     return <tr key={index}>{columnData}</tr>;
//   };

//   const tableData = () => {
//     return collection.map((key, index) => tableRows({ key, index }));
//   };

//   const headRow = () => {
//     return Object.values(tableHead).map((title, index) => (
//       <td key={index}>{title}</td>
//     ));
//   };

//   return (
//     <>
//       {/* <div class="search">
//         <input
//           placeholder="Search Campaign"
//           value={value}
//           onChange={e => setValue(e.target.value)}
//         />
//       </div> */}
//       <table>
//         <thead>
//           <tr>{headRow()}</tr>
//         </thead>
//         <tbody className="trhover">{tableData()}</tbody>
//       </table>
//       <Pagination
//         pageSize={countPerPage}
//         onChange={updatePage}
//         current={currentPage}
//         total={allData.length}
//       />
//     </>
//   );
// };
// export default Testing;


//filters
// import React, { useState } from 'react';

// const Testing = () => {
//   const options = ['Veg', 'Nonveg', 'New']; // Sample checkbox options

//   const [checkedItems, setCheckedItems] = useState({}); // State to hold checked status of checkboxes

//   // Function to handle checkbox change
//   const handleCheckboxChange = (event) => {
//     const { name, checked, value } = event.target;
//     const updatedCheckedItems = { ...checkedItems, [name]: checked ? value : undefined };
//     setCheckedItems(updatedCheckedItems);
//     console.log(updatedCheckedItems);
//   };

//   return (
//     <div>
//       {options.map((option, index) => (
//         <label key={index}>
//           <input
//             type="checkbox"
//             name={option}
//             value={option}
//             checked={checkedItems[option] || false}
//             onChange={handleCheckboxChange}
//           />
//           {option}
//         </label>
//       ))}

//         <label>
//           <input
//             type="checkbox"
//             name="veg"
//             value="1"
//             checked={checkedItems['veg'] || false}
//             onChange={handleCheckboxChange}
//           />
//           veg
//         </label>

//         <label>
//           <input
//             type="checkbox"
//             name="Nonveg"
//             value="1"
//             checked={checkedItems['Nonveg'] || false}
//             onChange={handleCheckboxChange}
//           />
//           Nonveg
//         </label>
//     </div>
//   );
// };
// export default Testing;

// import { Person } from "schema-dts";
// import { helmetJsonLdProp } from "react-schemaorg";
// import { Helmet } from "react-helmet";
// import {Helmet} from "react-helmet";
// function Testing(){

//   const ldJson = {
//   "@context": "https://schema.org",
//   "@type": "BreadcrumbList",
//   "itemListElement": [{
//     "@type": "ListItem",
//     "position": 1,
//     "name": "Blogs",
//     "item": "https://www.speblog.org"
//   },{
//     "@type": "ListItem",
//     "position": 2,
//     "name": "venky",
//     "item": window.location.href
//   }]
// };
// return (

// <>
// <Helmet>
// <script type="application/ld+json">
//   {JSON.stringify(ldJson)}
// </script>
// </Helmet>
// </>
// )
// }

// export default Testing;

// import React, { useState } from 'react';


// function Testing() {
//   const [tags, setTags] = useState([]);
//   const [inputValue, setInputValue] = useState('');

//   const handleInputChange = (event) => {
//     setInputValue(event.target.value);
//   };

//   const handleInputKeyDown = (event) => {
//     if (event.key === 'Enter' || event.key === ',') {
//       event.preventDefault(); // Prevent default behavior (form submission)
//       const newTag = inputValue.trim();
//       if (newTag) {
//         setTags([...tags, newTag]);
//         setInputValue(''); // Clear input after adding tag
//       }
//     }
//   };

//   const removeTag = (indexToRemove) => {
//     setTags(tags.filter((_, index) => index !== indexToRemove));
//   };

//   return (
//     <div className="App">
//       <div className="tags-input-container">
//         <div id="tags" className="tags">
//           {tags.map((tag, index) => (
//             <div key={index} className="tag">
//               {tag} <span className="tag-remove" onClick={() => removeTag(index)}>x</span>
//             </div>
//           ))}
//           <input
//             type="text"
//             id="tagInput"
//             value={inputValue}
//             onChange={handleInputChange}
//             onKeyDown={handleInputKeyDown}
//             className="tag-input"
//             placeholder="Add tags..."
//           />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Testing;


// import React, { useState,useEffect } from 'react';

// const Testing = () => {
//   // Sample data for the table

// const [data, setData] = useState([
//   { id: 1, name: 'John Doe', age: 30, email: 'john@example.com' },
//   { id: 2, name: 'Jane Smith', age: 25, email: 'jane@example.com' },
//   { id: 3, name: 'Bob Johnson', age: 40, email: 'bob@example.com' },
//   // Additional sample data...
// ]);




// const getData=(event)=>{
//   fetch("https://reqres.in/api/users?page=1", {
//     method: "GET",
//     headers: {
//       Accept: "application/json",
//       "Content-type": "application/json; charset=UTF-8",
//     },
//   })
//     .then((res) => res.json())
//     .then((resp) => {
//       let data = resp.data.data.sort((a, b) =>
//       a.first_name.localeCompare(b.first_name)
//     );
//      let cols = Object.keys(data[0]).map((key) => {
//        return {
//          Header: key.toUpperCase(),
//          accessor: key
//        };
//      });
//      setData(data);
//      //setColumns(cols);

//     })
//   .catch((err) => {
//     console.log(err.message);
//   });
// }

// useEffect(() => {

//   getData();
//   }, []);


// // Pagination
// const [currentPage, setCurrentPage] = useState(1);
// const itemsPerPage = 2;
// const totalPages = Math.ceil(data.length / itemsPerPage);

// const handleClick = (type) => {
//   if (type === 'prev') {
//     setCurrentPage(currentPage - 1);
//   } else if (type === 'next') {
//     setCurrentPage(currentPage + 1);
//   }
// };

// const indexOfLastItem = currentPage * itemsPerPage;
// const indexOfFirstItem = indexOfLastItem - itemsPerPage;
// const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

// return (
//   <div>
//     <h2>Data Table</h2>
//     <table>
//       <thead>
//         <tr>
//           <th>ID</th>
//           <th>Name</th>
//           <th>Age</th>
//           <th>Email</th>
//         </tr>
//       </thead>
//       <tbody>
//         {currentItems.map((row) => (
//           <tr key={row.id}>
//             <td>{row.id}</td>
//             <td>{row.name}</td>
//             <td>{row.age}</td>
//             <td>{row.email}</td>
//           </tr>
//         ))}
//       </tbody>
//     </table>
//     <div>
//       <button
//         onClick={() => handleClick('prev')}
//         disabled={currentPage === 1}
//       >
//         Previous
//       </button>
//       <span>{`Page ${currentPage} of ${totalPages}`}</span>
//       <button
//         onClick={() => handleClick('next')}
//         disabled={currentPage === totalPages}
//       >
//         Next
//       </button>
//     </div>
//   </div>
// );
// };

// export default Testing;


// import React, { useState } from 'react';

// function Testing() {
//   const [items, setItems] = useState(['Item 1', 'Item 2', 'Item 3', 'Item 4']);
//   const [inputValues, setInputValues] = useState([...items]); // Store input values for editing

//   const handleInputChange = (index, value) => {
//     const newInputValues = [...inputValues];
//     newInputValues[index] = value;
//     setInputValues(newInputValues);
//   };

//   const moveItemUp = (index) => {
//     if (index === 0) return; // Cannot move the first item up
//     const newItems = [...items];
//     const temp = newItems[index];
//     newItems[index] = newItems[index - 1];
//     newItems[index - 1] = temp;
//     setItems(newItems);
//     setInputValues([...newItems]); // Update input values after moving
//   };

//   const moveItemDown = (index) => {
//     if (index === items.length - 1) return; // Cannot move the last item down
//     const newItems = [...items];
//     const temp = newItems[index];
//     newItems[index] = newItems[index + 1];
//     newItems[index + 1] = temp;
//     setItems(newItems);
//     setInputValues([...newItems]); // Update input values after moving
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log('Submitted items:', items);
//     // You can perform further actions like sending the items to a backend server here
//   };

//   return (
//     <div>
//       <h2>Edit List</h2>
//       <form onSubmit={handleSubmit}>
//         <ul>
//           {inputValues.map((value, index) => (
//             <li key={index}>
//               <input
//                 type="text"
//                 value={value}
//                 onChange={(e) => handleInputChange(index, e.target.value)}
//               />
//               <button type="button" onClick={() => moveItemUp(index)} disabled={index === 0}>
//                 Move Up
//               </button>
//               <button type="button" onClick={() => moveItemDown(index)} disabled={index === items.length - 1}>
//                 Move Down
//               </button>
//             </li>
//           ))}
//         </ul>
//         <button type="submit">Submit</button>
//       </form>
//     </div>
//   );
// }

// export default Testing;


// import React, { useState, useEffect } from "react";
// import { sortableContainer, sortableElement } from "react-sortable-hoc";

// const itemStyles = {
//   padding: "10px",
//   border: "1px #000 solid",
//   backgroundColor: "#FFF",
//   margin: "10px"
// };

// const SortableItem = sortableElement(({ user }) => {
//   return <div style={itemStyles}>{user.name}</div>;
// });

// const SortableContainer = sortableContainer(({ children }) => {
//   return <div className="container">{children}</div>;
// });

// const Testing = () => {
//   const [newUser, setNewUser] = useState("");
//   const [users, setUsers] = useState([
//     { name: "user 0", order: 0 },
//     { name: "user 1", order: 1 },
//     { name: "user 2", order: 2 }
//   ]);

//   useEffect(() => {}, [users]);

//   const onSortEnd = ({ oldIndex, newIndex }) => {
//     setUsers(prevState => {
//       const newItems = [...prevState];
//       if (oldIndex > newIndex) {
//         for (let i = oldIndex - 1; i >= newIndex; i--) {
//           newItems[i].order++;
//           newItems[oldIndex].order = newIndex;
//         }
//       } else if (oldIndex < newIndex) {
//         for (let i = oldIndex + 1; i <= newIndex; i++) {
//           newItems[i].order--;
//           newItems[oldIndex].order = newIndex;
//         }
//       }
//       return newItems.sort((a, b) => a.order - b.order);
//     });
//   };

//   const onSubmit = e => {
//     e.preventDefault();
//     // Handle submission of users
//     console.log("Submitted users:", users);
//     // You can perform further actions like sending the users to a backend server here
//     setNewUser("");
//   };

//   const addUser = () => {
//     const user = { name: newUser, order: users.length };
//     setUsers(prevState => [...prevState, user]);
//   };

//   return (
//     <div>
//       <SortableContainer onSortEnd={onSortEnd}>
//         {users.map((user, index) => (
//           <SortableItem key={index} index={index} user={user} />
//         ))}
//       </SortableContainer>
//       <form onSubmit={onSubmit}>
//         <button type="submit">Submit</button>
//       </form>
//     </div>
//   );
// };



// export default Testing;


// import React, { useState, useRef } from 'react';

// const Testing = () => {
//   const [isPlaying, setIsPlaying] = useState(false);
//   const videoRef = useRef(null);

//   const togglePlay = () => {
//     if (videoRef.current.paused) {
//       videoRef.current.play();
//       setIsPlaying(true);
//     } else {
//       videoRef.current.pause();
//       setIsPlaying(false);
//     }
//   };

//   return (
//     <div>
//       <video
//         ref={videoRef}
//         controls
//         onClick={togglePlay}
//         // Add additional attributes like src, width, height, etc. as needed
//       >
//         <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4" />
//         {/* Add additional source tags for other video formats */}
//         Your browser does not support the video tag.
//       </video>
//       <button onClick={togglePlay}>
//         {isPlaying ? 'Pause' : 'Play'}
//       </button>
//     </div>
//   );
// };

// export default Testing;

// import React from 'react';
// import qs from 'qs';
// import InstagramEmbed from 'react-instagram-embed';

// function Testing() {
//   return (
//     <div>
//       {/* Replace the 'url' prop value with the URL of the Instagram post or video */}
//       <InstagramEmbed
//   url='https://www.instagram.com/p/BOzamYMA-vb/'
//   clientAccessToken='123|456'
//   maxWidth={320}
//   hideCaption={false}
//   containerTagName='div'
//   protocol=''
//   injectScript
//   onLoading={() => {}}
//   onSuccess={() => {}}
//   onAfterRender={() => {}}
//   onFailure={() => {}}
// />
//     </div>
//   );
// }

// export default Testing;

// App.js
import React, { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SortableContainer from './SortableContainer';

const Testing = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch('https://apimenu3.haikutheasiankitchen.com/index.php/Api/allcategoriestest')
      .then((response) => response.json())
      .then((data) => setCategories(data.data));
  }, []);

  const moveCategory = (fromIndex, toIndex) => {
    setCategories((prevCategories) => {
      const updatedCategories = [...prevCategories];
      const [movedItem] = updatedCategories.splice(fromIndex, 1);
      updatedCategories.splice(toIndex, 0, movedItem);
      return updatedCategories;
    });
  };

  const moveSubCategory = (fromParentIndex, fromIndex, toParentIndex, toIndex) => {
    setCategories((prevCategories) => {
      const updatedCategories = [...prevCategories];
      const fromParentCategory = updatedCategories[fromParentIndex];
      const toParentCategory = updatedCategories[toParentIndex];
      const [movedSubCategory] = fromParentCategory.sub_cat.splice(fromIndex, 1);
      toParentCategory.sub_cat.splice(toIndex, 0, movedSubCategory);
      return updatedCategories;
    });
  };

  const saveAllOrder = () => {
    // Save category order logic here
    console.log('Saving order:', categories);
    const categoryOrder = categories.map((category, index) => ({ id: category.category.id, order: index }));
    const subCategoryOrder = categories.flatMap((category, parentIndex) =>
      category.sub_cat.map((subCategory, subIndex) => ({
        id: subCategory.id,
        parentId: category.category.id,
        order: subIndex,
      }))
    );

    fetch('https://apimenutwo.haikutheasiankitchen.com/index.php/Api/categoryorder', {
      method: 'POST',
      body: JSON.stringify({
        data: categoryOrder,
      }),
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log('Category order saved successfully');
      })
      .catch((err) => {
        console.log(err.message);
      });

    fetch('https://apimenutwo.haikutheasiankitchen.com/index.php/Api/subcategoryorder', {
      method: 'POST',
      body: JSON.stringify({
        data: subCategoryOrder,
      }),
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log('Subcategory order saved successfully');
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="App">
        <h1>Sortable Categories</h1>
        <SortableContainer
          categories={categories}
          moveCategory={moveCategory}
          moveSubCategory={moveSubCategory}
          saveAllOrder={saveAllOrder}
        />
      </div>
    </DndProvider>
  );
};

export default Testing;
