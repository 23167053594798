// Category.js
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
// import { ItemType } from './constants';

// constants.js
export const ItemType = {
  CATEGORY: 'CATEGORY',
  SUB_CATEGORY: 'SUB_CATEGORY'
};

const Category = ({ category, index, moveCategory, moveSubCategory }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemType.CATEGORY,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemType.CATEGORY,
    hover: (draggedItem) => {
      if (draggedItem.index === index) return;
      moveCategory(draggedItem.index, index);
      draggedItem.index = index;
    },
  });

  const [, dropSubCategory] = useDrop({
    accept: ItemType.SUB_CATEGORY,
    hover: (draggedItem) => {
      if (draggedItem.parentIndex === index) return;
      moveSubCategory(draggedItem.parentIndex, draggedItem.index, index, 0);
      draggedItem.parentIndex = index;
      draggedItem.index = 0;
    },
  });

  return (
    <div ref={(node) => drag(drop(node))} style={{ opacity: isDragging ? 0.5 : 1, padding: '8px', border: '1px dashed gray', marginBottom: '4px' }}>
      {category.category.name}
      <div ref={dropSubCategory} style={{ marginLeft: '20px' }}>
        {category.sub_cat.map((subCategory, subIndex) => (
          <SubCategory key={subCategory.id} subCategory={subCategory} parentIndex={index} index={subIndex} moveSubCategory={moveSubCategory} />
        ))}
      </div>
    </div>
  );
};

const SubCategory = ({ subCategory, index, parentIndex, moveSubCategory }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemType.SUB_CATEGORY,
    item: { parentIndex, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemType.SUB_CATEGORY,
    hover: (draggedItem) => {
      if (draggedItem.parentIndex === parentIndex && draggedItem.index === index) return;
      moveSubCategory(draggedItem.parentIndex, draggedItem.index, parentIndex, index);
      draggedItem.index = index;
      draggedItem.parentIndex = parentIndex;
    },
  });

  return (
    <div ref={(node) => drag(drop(node))} style={{ opacity: isDragging ? 0.5 : 1, padding: '8px', border: '1px dashed gray', marginBottom: '4px' }}>
      {subCategory.name}
    </div>
  );
};

export default Category;
