import React from 'react';
//import { Navigate, Outlet } from 'react-router-dom';
import { Route, Redirect } from 'react-router-dom';

// const PrivateRoute = () => {
//     const auth = null; // determine if authorized, from context or however you're doing it

//     // If authorized, return an outlet that will render child elements
//     // If not, return element that will navigate to login page
//     return auth ? <Outlet /> : <Navigate to="/login" />;
// }
function PrivateRoute({ component: Component, isAuthenticated, ...rest }) {
  return (
    <Route {...rest} render={(props) => (
      isAuthenticated ? <Component {...props} />
    : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
  );
}

export default PrivateRoute;
