// import React, { useState, useEffect } from "react";
// import { sortableContainer, sortableElement } from "react-sortable-hoc";
// import axios from 'axios';

// const itemStyles = {
//   padding: "10px",
//   border: "1px #000 solid",
//   backgroundColor: "#FFF",
//   margin: "10px"
// };

// const SortableItem = sortableElement(({ user }) => {
//   return <div style={itemStyles}>{user.name}</div>;
// });

// const SortableContainer = sortableContainer(({ children }) => {
//   return <div className="container">{children}</div>;
// });

// function AddCategoryOrder() {
//   const [newUser, setNewUser] = useState("");
//   const [users, setUsers] = useState([]);

//   useEffect(() => {
//     loadCategory();
//   }, []);

//   const loadCategory = () => {
//     fetch("https://apimenu3.haikutheasiankitchen.com/index.php/Api/allcategories", {
//       method: "GET",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "multipart/form-data",
//       },
//     })
//       .then((res) => res.json())
//       .then((result) => {
//         if (result.data != "") {
//           setUsers(result.data.map((user, index) => ({ ...user, order: index })));
//         }
//       })
//       .catch((err) => {
//         console.log(err.message);
//       });
//   };

//   const onSortEnd = ({ oldIndex, newIndex }) => {
//     setUsers((prevState) => {
//       const newItems = [...prevState];
//       const movedItem = newItems.splice(oldIndex, 1)[0];
//       newItems.splice(newIndex, 0, movedItem);
//       return newItems.map((user, index) => ({
//         ...user,
//         order: index
//       }));
//     });
//   };

//   const onSubmit = (e) => {
//     e.preventDefault();
//     // Handle submission of users
//     //console.log("Submitted users:", users);
//     // You can perform further actions like sending the users to a backend server here
//     fetch("https://apimenutwo.haikutheasiankitchen.com/index.php/Api/categoryorder", {
//       method: "POST",
//       body: JSON.stringify({
//           data: users,
//       }),
//       headers: {
//         Accept: "application/json",
//         "Content-type": "application/json; charset=UTF-8",
//       },
//     })
//       .then((res) => res.json())
//       .then((result) => {
//         //console.log(result);
//         alert('Category sorting updated successfully');
//       })
//     .catch((err) => {

//       console.log(err.message);
//     });

//   };

//   return (
//     <div className="ms-content-wrapper">
//       <div className="row">
//         <div className="col-xl-12 col-md-12">
//           <div className="ms-panel ms-panel-fh">
//             <div className="ms-panel-header">
//               <h6>Category Order</h6>
//             </div>
//             <div className="ms-panel-body">
//               <form className="needs-validation clearfix" onSubmit={onSubmit}>
//                 <div className="form-row">
//                   <div className="col-md-12 mb-3">
//                     <SortableContainer onSortEnd={onSortEnd}>
//                       {users.map((user, index) => (
//                         <SortableItem key={index} index={index} user={user} />
//                       ))}
//                     </SortableContainer>
//                   </div>
//                   <div className="ms-panel-header new">
//                     <button className="btn btn-primary d-block" type="submit">
//                       Save
//                     </button>
//                   </div>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default AddCategoryOrder;

import React, { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SortableContainer from '../../../SortableContainer';

const AddCategoryOrder = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch('https://apimenu3.haikutheasiankitchen.com/index.php/Api/allcategoriestest')
      .then((response) => response.json())
      .then((data) => setCategories(data.data));
  }, []);

  const moveCategory = (fromIndex, toIndex) => {
    setCategories((prevCategories) => {
      const updatedCategories = [...prevCategories];
      const [movedItem] = updatedCategories.splice(fromIndex, 1);
      updatedCategories.splice(toIndex, 0, movedItem);
      return updatedCategories;
    });
  };

  const moveSubCategory = (fromParentIndex, fromIndex, toParentIndex, toIndex) => {
    setCategories((prevCategories) => {
      const updatedCategories = [...prevCategories];
      const fromParentCategory = updatedCategories[fromParentIndex];
      const toParentCategory = updatedCategories[toParentIndex];
      const [movedSubCategory] = fromParentCategory.sub_cat.splice(fromIndex, 1);
      toParentCategory.sub_cat.splice(toIndex, 0, movedSubCategory);
      return updatedCategories;
    });
  };

  const saveAllOrder = () => {
    // Save category order logic here
    console.log('Saving order:', categories);
    const categoryOrder = categories.map((category, index) => ({ id: category.category.id, order: index }));
    const subCategoryOrder = categories.flatMap((category, parentIndex) =>
      category.sub_cat.map((subCategory, subIndex) => ({
        id: subCategory.id,
        parentId: category.category.id,
        order: subIndex,
      }))
    );

    fetch('https://apimenu3.haikutheasiankitchen.com/index.php/Api/categoryorder', {
      method: 'POST',
      body: JSON.stringify({
        data: categoryOrder,
      }),
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log('Category order saved successfully');
      })
      .catch((err) => {
        console.log(err.message);
      });

    fetch('https://apimenu3.haikutheasiankitchen.com/index.php/Api/subcategoryorder', {
      method: 'POST',
      body: JSON.stringify({
        data: subCategoryOrder,
      }),
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log('Subcategory order saved successfully');
      })
      .catch((err) => {
        console.log(err.message);
      });
      alert("Order Updated Successfully");
  };

  return (
    <>
        <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-xl-12 col-md-12">
          <div className="ms-panel ms-panel-fh">
            <div className="ms-panel-header">
              <h6>Category Order</h6>
            </div>
            <div className="ms-panel-body">
              <DndProvider backend={HTML5Backend}>
                <div className="form-row">
                  <div className="col-md-12 mb-3">
                      {/* <h1>Sortable Categories</h1> */}
                      <SortableContainer
                        categories={categories}
                        moveCategory={moveCategory}
                        moveSubCategory={moveSubCategory}
                        saveAllOrder={saveAllOrder}
                      />
                    </div>
                  </div>
              </DndProvider>
            </div>
          </div>
        </div>
      </div>
    </div>


    
   </>
  );
};

export default AddCategoryOrder;

