import React, { useEffect, useState } from "react";
import { useToggleClick } from './Menu';
import logo from './logo.png';
import pin from './pin.png';
import zomato from './zomato.png';
import swiggy from './swiggy.png';
import dineout from './dineout.png';
import whatsapp from './whatsapp.png';
import { Helmet } from "react-helmet";

const Header = () => {
  const handleToggleClick = useToggleClick();
  const [logo, setLogo] = useState('');

  useEffect(() => {
    loadLogo();
 }, []);


 const loadLogo=()=>{
  fetch("https://apimenu3.haikutheasiankitchen.com/index.php/Api/logo", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        //console.log(result);
        if (result.data != "") {
          const data = result.data;
          setLogo(data);
        }
      })
    .catch((err) => {
      console.log(err.message);
    });
}

    return (
      <>
    <Helmet>

      <title>{logo.title}</title>
      <link id="favicon" rel="icon" href={logo.image} type="image/x-icon"/>
      <meta
        name="description"
        content="Discover the essence of Nove - The Italian Kitchen - where flavors come alive. Explore our menu for a culinary journey like no other. Indulge today!"
      />
      <meta
        name="keywords"
        content="Haiku | The Asian Kitchen"
      />

    </Helmet>       
    <header className="main_header" id="Header">
      <div className="main-header">
        <div className="container">
          {/* <div className="top-header">
            <div className="location">
              <img src={pin} alt="location" />
              <span>2900 Lapeer Rd, Port Hurons, MI 49070</span>
            </div>
            <div className="call-now">
              <span>
                Call Now: <a href="tel:+917337557853">7337557853</a>
              </span>
            </div>
          </div> */}
          <div className="bottom-header">
            <div className="logo-cont">
            <a href="/"> <img src={logo.image} alt="logo" /></a>
            </div>
            <ul className="main-menu">
              <li>
                <a href="/">Home</a>
              </li>

              <li>
                <a href="/menu">Menu</a>
              </li>

            </ul>
            {/* <div className="delivery-partners">
              <ul>
                <li><a href="https://www.swiggy.com/menu/825382?source=sharing"><img src={swiggy} alt="Swiggy"></img></a></li>
                <li><a href="https://zomato.onelink.me/xqzv/mytjqvfh"><img src={zomato} alt="Zomato"></img></a></li>
              
              </ul>
            </div> */}
            <div className="toogle-bar">
            <div className="delivery-partners">
              <ul>
                {/* <li><a href="https://www.swiggy.com/menu/825382?source=sharing"><img src={swiggy} alt="Swiggy"></img></a></li>
                <li><a href="https://www.zomato.com/hyderabad/haiku-gachibowli"><img src={zomato} alt="Zomato"></img></a></li> */}
                <li><a href="https://feedback.haikutheasiankitchen.com">Feedback</a></li>
              </ul>
            </div>

              <div id="toggleNav" onClick={handleToggleClick}>
                <span className="MenuBtn">
                  <i />
                  <i />
                  <i />
                </span>
              </div>
              <div id="megaMenu" className="menuNavigation" style={{}}>
                <div className="container">
                  <ul className="listItems">
                    <li>
                      <a href="/">Home</a>
                    </li>

                    <li>
                      <a href="/menu">Menu</a>
                    </li>

                    <li>
                      <a href="https://haikutheasiankitchen.com/contact-us/">Contact Us</a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    {/* <div className="sticky-whatsapp">
      <a href="https://api.whatsapp.com/send?phone=917095876888"><img src={whatsapp} alt="whatsapp-icon"/></a>
    </div> */}
    </>
    );
  };
  export default Header;
