import React, { useEffect, useState } from "react";
import Header from './Header.js';
import Stickyheader from './Stickyheader.js';
import "./App.css";
import veg from '../frontend/veg.jpg';
import nonveg from '../frontend/non-veg.png';
import egg from '../frontend/egg.jpg';
import img1 from "../frontend/articleimg.png";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
import { useHistory ,useLocation } from 'react-router-dom';
import facbook from '../frontend/facebook_share.png';
import twitter from '../frontend/twitter.png';
import whatsapp from '../frontend/whatsapp_share.png';
import share from '../frontend/share.png';



const Details = () => {
  const location = useLocation(); // React Hook
  let product_name = (location.pathname.split('/')[2]);
  let parts = product_name.split('-');
  let ID = parts[parts.length - 1];
  //alert(lastID);
  const params = useParams();
  const mainid= 1;//params.mainid.toString();
  let history = useHistory();
  const mainDomain = localStorage.getItem('domain');

  const [productData, setproductData] = useState([]);
  const [productAddon, setproductAddon] = useState([]);
  const [productPrice, setproductPrice] = useState([]);
  const [logo, setLogo] = useState('');
  const [currentDate, setCurrentDate] = useState(getDate());
  const domain="https://menu1.haikutheasiankitchen.com/";
  const pname=productData?productData.name:'';

  useEffect(() => {
    productsData();
    loadLogo();
  }
  , []);

  const productsData=()=>{

    fetch("https://apimenu3.haikutheasiankitchen.com/index.php/Api/Productdata", {
      method: "POST",
      body: JSON.stringify({
        pid: ID,
      }),
      headers: {
        Accept: "application/json",
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        //console.log(result);
        if (result.data != "") {
          setproductData(result.data.prodInfo);
          setproductAddon(result.data.addonData);
          setproductPrice(result.data.prodPrice);
        }
      })
    .catch((err) => {
      console.log(err.message);
    });
}


const loadLogo=()=>{
  fetch("https://apimenu3.haikutheasiankitchen.com/index.php/Api/logo", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        //console.log(result);
        if (result.data != "") {
          const data = result.data;
          setLogo(data);
        }
      })
    .catch((err) => {
      console.log(err.message);
    });
}

function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  return `${month}/${date}/${year}`;
}

const ldJson =     {
  "@context": "https://schema.org/",
  "@type": "Recipe",
  "name": productData.name,
  "image": [
    mainDomain+productData.image
  ],
  "author": {
    "@type": "Person",
    "name": "Haiku | The Asian Kitchen"
  },
  "datePublished": currentDate,
  "description": productData.description,
  //
  "offers": {
    "@type": "Offer",
                  "price": productData.current_price,
                  "priceCurrency": "INR"
  },

  //
  "recipeCuisine": "Italian",
  "prepTime": "PT1M",
  "cookTime": "PT2M",
  "totalTime": "PT3M",
  "keywords": productData.keywords,//"veg/non-veg"
  "recipeYield": "4 servings",
  "recipeCategory": productData.category_name,
  "nutrition": {
    "@type": "NutritionInformation",
    "calories": "120 calories"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "ratingCount": "18"
  },
  "recipeIngredient": [
    productData.description,
  ],
  // "recipeInstructions": [
  //   {
  //     "@type": "HowToStep",
  //     "name": "Blend",
  //     "text": "Blend 400ml of pineapple juice and 100ml cream of coconut until smooth.",
  //     "url": "https://example.com/non-alcoholic-pina-colada#step1",
  //     "image": "https://example.com/photos/non-alcoholic-pina-colada/step1.jpg"
  //   },
  //   {
  //     "@type": "HowToStep",
  //     "name": "Fill",
  //     "text": "Fill a glass with ice.",
  //     "url": "https://example.com/non-alcoholic-pina-colada#step2",
  //     "image": "https://example.com/photos/non-alcoholic-pina-colada/step2.jpg"
  //   },
  //   {
  //     "@type": "HowToStep",
  //     "name": "Pour",
  //     "text": "Pour the pineapple juice and coconut mixture over ice.",
  //     "url": "https://example.com/non-alcoholic-pina-colada#step3",
  //     "image": "https://example.com/photos/non-alcoholic-pina-colada/step3.jpg"
  //   }
  // ],
  // "video": {
  //   "@type": "VideoObject",
  //   "name": "How to Make a Non-Alcoholic Piña Colada",
  //   "description": "This is how you make a non-alcoholic piña colada.",
  //   "thumbnailUrl": [
  //     "https://example.com/photos/1x1/photo.jpg",
  //     "https://example.com/photos/4x3/photo.jpg",
  //     "https://example.com/photos/16x9/photo.jpg"
  //    ],
  //   "contentUrl": "https://www.example.com/video123.mp4",
  //   "embedUrl": "https://www.example.com/videoplayer?video=123",
  //   "uploadDate": "2018-02-05T08:00:00+08:00",
  //   "duration": "PT1M33S",
  //   "interactionStatistic": {
  //     "@type": "InteractionCounter",
  //     "interactionType": { "@type": "WatchAction" },
  //     "userInteractionCount": 2347
  //   },
  //   "expires": "2019-02-05T08:00:00+08:00"
  //  }
}

const getImageSource = (typeId) => {
  if (typeId === '1') {
    return veg;
  } else if (typeId === '2') {
    return egg;
  } else if (typeId === '3') {
    return nonveg;
  } else  {
    return 'vegnonveg';
  }
};


let type = "";
const getPrice = (pricing, name,dish_type) => {
  if (!pricing) {
    return null; // or return an empty array, depending on your requirements
  }

  const prices = pricing.split(',');
  const classNames = name.split(',');
  const dishtype = dish_type.split(',');

  return prices.map((item, index) => (
    <span key={index} className={dishtype&&dishtype[index]+' text-capitalize'}>{classNames[index]&& classNames[index]+' -'} &#x20b9;{item}</span>
  ));
};


  return (
    <>
      
    <Helmet>
      <meta property="og:image" content={mainDomain+productData.image}  />
      <title>{logo.title}</title>
      <link id="favicon" rel="icon" href={logo.image} type="image/x-icon"/>
      <meta
        name="description"
        content="Discover the essence of Nove - The Italian Kitchen - where flavors come alive. Explore our menu for a culinary journey like no other. Indulge today!"
      />
      <meta
        name="keywords"
        content="Haiku | The Asian Kitchen"
      />
   
      <meta property="og:image:url" content={mainDomain+productData.image}/>
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:secure" content={mainDomain+productData.image}></meta>
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="628" />
      <link rel="canonical" href={domain + 'menu-details/' + product_name} />
    <script type="application/ld+json">
      {JSON.stringify(ldJson)}
    </script>
    </Helmet>
    <Header />
<div>
        {/* <div className="black-patch">
        <button onClick={history.goBack}>Go Back</button>
          <a href={"/menu/"} className="back-button">
            <div className="l-arrow-stick" />
          </a>
        </div> */}
        <div className="single-fluid">
          <div className="container">
            <div className="singlepage-content">
              <div className="article-img">
                <img src={mainDomain+productData.image} />
              </div>
              <div className="main-description">
                <div className="description">
                  <div className="name-desk">
                    <div className="item-name">
                    {getImageSource(productData.type_id)=='vegnonveg'?(
                      <div>
                       <img src={veg} alt="" className="multicat"/>
                       <img src={nonveg} alt=""/>
                       </div>
                    ):(
                      <img src={getImageSource(productData.type_id)} alt="" />
                    )}
                      <h3 className="text-capitalize profont">{productData.dish_name}</h3>
                    </div>
                    <div className="cost">
                    {productPrice.map(data => (
                      <span key={data.id} className={data.dish_type&&data.dish_type+' text-capitalize'}>{data.name&& data.name+' -'} &#x20b9;{data.price}</span>
                    ))}
                      {/* {getPrice(productData.price,productData.dish_price_tag)} */}
                      {/* {getPrice(productData.current_price,productData.type_id)} */}
                    </div>
                  </div>
                  <p className="text-capitalize profont">{productData.dish_description}</p>
                  <div className="article-social-share">
                  
                  {/* {productData.name && (
                    <a href={'http://www.facebook.com/sharer.php?u=' + domain + 'menu-details/' + product_name} target="_blank">
                      <div className="post-share facebook"><img width={30} height={30} src={facbook} alt="Facebook" title="Facebook" /></div>
                    </a>
                  )}
                  {productData.name && (
                    <a href={'https://twitter.com/intent/tweet?url=' + domain + 'menu-details/' + product_name + '&amp;text=' + productData.name} target="_blank">
                      <div className="post-share twitter"><img width={30} height={30} src={twitter} alt="Twitter" title="Twitter" /></div>
                    </a>
                  )} */}
                  {productData.name && (
                    <a href={'whatsapp://send?text='+productData.name + '%0a' + domain + 'menu-details/' + product_name} target="_blank">
                      <div className="post-share whatsapp"><img width={20}  src={share} alt="whatsapp" title="whatsapp" /></div>
                    </a>
                  )}
                  </div>
                </div>
          
                <div className="addons mt-4">
                  
                 {productAddon!="" && <h3>Add-ons</h3>}
                  {productAddon.map(addon => (
                  <div className="addon-item">
                    <span>{addon.add_on_name}</span>
                    <span className="cost">+{addon.price}</span>
                  </div>
                   ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

</>
  )
}

export default Details;