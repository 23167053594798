import React, { useEffect, useState } from "react";
import Breadcrumb from './Breadcrumb';
import Productslider from './Productslider';
import axios from 'axios';
import { useParams } from "react-router";

function Editcategorycontent() {

  const params = useParams();
  const id= params.id.toString();
  
  const [maincategoryDropdown, setMaincategoryDropdown] = useState([]);

    const [formData, setFormData] = useState({
        name: '',
        main_category_id:''
      });
    
    useEffect(() => {
      loadData();
      // mainCatDropdown();
    }, []);


    const loadData=()=>{
      fetch("https://apimenu3.haikutheasiankitchen.com/index.php/Api/Categorydata", {
          method: "POST",
          body: JSON.stringify({
            id: id,
          }),
          headers: {
          Accept: "application/json",
          "Content-type": "application/json; charset=UTF-8",
          },
      })
          .then((res) => res.json())
          .then((result) => {
          //console.log(result);

              if (result.data!=="") {
                  //setProdName(result.data.prodInfo.name)
                  setFormData(result.data);
                 
              }
          })
      .catch((err) => {
          //this.setState({ error: 'Invalid username or password' });
          console.log(err.message);

      });
  
}

//     const mainCatDropdown=()=>{
//         fetch("https://apimenu3.haikutheasiankitchen.com/index.php/Api/maincategories", {
//             method: "GET",
//             headers: {
//               Accept: "application/json",
//               "Content-Type": "multipart/form-data",
//               //"Content-type": "application/json; charset=UTF-8",
//             },
//           })
//             .then((res) => res.json())
//             .then((result) => {
//               //console.log(result);
//               if (result.data != "") {
//                 const data = result.data;
//                 setMaincategoryDropdown(data);
//                 handlemaincatChange();
    
//               }
//             })
//           .catch((err) => {
//             console.log(err.message);
//           });
// }



  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


const handlemaincatChange=(event)=>{

    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });

}

  const handleSubmit = async(event) => {
    event.preventDefault();

      fetch("https://apimenu3.haikutheasiankitchen.com/index.php/Api/updatecategory", {
        method: "POST",
        body: JSON.stringify({
          id: id,
          name:formData.name,
          main_category_id:1
        }),
        headers: {
        Accept: "application/json",
        "Content-type": "application/json; charset=UTF-8",
        },
    })
        .then((res) => res.json())
        .then((result) => {
        //console.log(result);
          window.location.href = '/category';

        })
    .catch((err) => {
        //this.setState({ error: 'Invalid username or password' });
        console.log(err.message);

    });


  };

        return (
            <div className="ms-content-wrapper">
                <div className="row">
                    <div className="col-md-12" >
                        <Breadcrumb/>
                        <div className="alert alert-success" role="alert" style={{display:  'none' }}>
                            <strong>Well done!</strong> You successfully read this important alert message.
                        </div>
                    </div>
                    <div className="col-xl-12 col-md-12">
                        <div className="ms-panel ms-panel-fh">
                            <div className="ms-panel-header">
                                <h6>Edit Category</h6>
                            </div>
                            <div className="ms-panel-body">
                                <form className="needs-validation clearfix"  onSubmit={handleSubmit} key="editform">
                                    <div className="form-row">
                                        
                                        {/* <div className="col-md-12 mb-3">
                                            <label htmlFor="main_category_id">Select Main Category</label>
                                            <div className="input-group">
                                                <select className="form-control" required id="main_category_id"  name="main_category_id"   onChange={handlemaincatChange} >
                                                <option value="">--Select--</option>
                                                {maincategoryDropdown.map((item, i) => ( 
                                                    <option value={item.id} key={i} selected={item.id === formData.main_category_id}>{item.category_name}</option>
                                                 ))}
                                                    
                                                </select>
                                                <div className="invalid-feedback">
                                                    Please select a Catagory.
                                                </div>
                                            </div>
                                        </div> */}
                                      
                                        
                                      
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="name">Category</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="name" placeholder="Enter sub category" required name="name" value={formData.name} onChange={handleInputChange}/>
                                                
                                            </div>
                                        </div>
              
                                        <div className="ms-panel-header new">
                                          <button className="btn btn-primary d-block" type="submit" >Save</button>
                                        </div>
                                     
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
              
                </div>
            </div>

        );

}

export default Editcategorycontent;